<template>
<!-- 首页a+b列表页 -->
<div>
<el-col :span="4" class="item"  v-if="productType=='AconnectB'">
    <div class="imgWrap"  @click="jumpDetail(info.id,info.promotionId)">
      <img
        :alt="info.name | defaultName"
         :src="info.pic?info.pic + picSuffix : defaultImage"
      />
    </div>
    <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
    <div class="priceBox">
      <!-- <span class="labelBox">
        a+b
        <div class="arrow-right"></div>
      </span> -->
      <span class="price">{{info.price  | capitalize}}</span>
      <del class="delPrice">{{info.originalPrice  | capitalize}}</del>
    </div>
    <p class="msg">热销中</p>
  </el-col>
  <el-col :span="4" class="item"  v-if="productType=='setMeal'">
    <div class="imgWrap"  @click="CommonJumpDetail(info.id,info.isRecentExpiration)">
      <img
        :alt="info.name | defaultName"
        :src="( url+ picSuffix) | defaultImage"
      />
    </div>
    <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
    <p class="msg">{{info.specification}}</p>
    <p class="msg" :title="info.manufacturerName">{{info.manufacturerName}}</p>
    <div class="priceBox">
      <!-- <span class="labelBox">
        套餐价
        <div class="arrow-right"></div>
      </span> -->
      <span class="price">{{info.price  | capitalize}}</span>
      <del class="delPrice">{{info.originalPrice  | capitalize}}</del>
      <!-- <span class="num">数量：{{info.minOrderNum}}</span> -->
    </div>
  </el-col>
</div>
  
</template>
<style lang="less" scoped>
@import "~style/index.less";
.item {
  background: #fff;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom:10px;

  .imgWrap {
    padding: 15px 0;
    overflow: hidden;
    img {
      display: inline-block;
      width:176px;
      height: 176px;
      margin: 0 auto;
      cursor: pointer;
      &:hover {
        box-shadow: 10px 6px 6px -4px #dedada;
        border-radius: 7px;
      }
    }
  }
  .shopName {
    height: 22px;
    color: #333;
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // overflow:hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp:2;
    // -webkit-box-orient: vertical;
  }
  .priceBox {
    margin-top:5px;
    height:26px;
    line-height:26px;
    font-size: 12px;
    color: #999;
    // .labelBox {
    //     display: inline-block;
    //   background: #FFD303;
    //   padding: 0 5px;
    //   color: #000;
    //   text-align: center;
    //   line-height:20px;
    //   position: relative;
    //   .arrow-right {
    //         position: absolute;
    //         right: -4px;
    //         width: 6px;
    //         height: 6px;
    //         top: 6px;
    //         background: #FFD303;
    //         border-top: 2px solid #FFD303;
    //         border-right: 2px solid #FFD303;
    //         transform: rotate(45deg);
    //   }
    // }
    .price{
        color: #f43c38;
        font-size:16px;
        font-weight: bold;
        display: inline-block;
    }
    .delPrice{
        padding-left:10px;
        color: #929598;
        font-size: 12px;
    }
    .num{
       color: #929598;
        font-size: 12px;
        float: right;
    }
  }
  .msg{
        color: #929598;
        font-size: 12px;
        text-indent: 3px;
        line-height: 20px;
        width: 100%;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        cursor: pointer;
    }
}
</style>

<script>
import defaultImage from "@/assets/defaultImage.png";
export default {
  name: "ComboItem",
  data() {
    return {
      picSuffix: localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      status: false,
      num: 0,
      // pics: 1,
      defaultImage: defaultImage,
      promotionMap: "",
    };
  },
  props: {
    info: {
      type: Object,
    },
    productType: {
      type: String,
    },
  },
  methods: {
    jumpDetail(id, promotionId) {
      this.$router.push({
         name: "ComboDetail",
        query: { productId: id, promotionId: promotionId },
      });
    },
    CommonJumpDetail(id,isRecentExpiration) {
      this.$router.push({ name: "ProductDetail", query: { id: id,isRecentExpiration:isRecentExpiration<0?0:isRecentExpiration } });
    },
  },
  created() {
    console.log(this.info)
    if(this.productType=='setMeal'){
      this.url=this.info.albumPics.split(",")[0]
    }
      console.log('超值套餐'+this.info)
  },
  updated() {},
};
</script>
